
//   window.onkeydown = function(e) {
//       return !(e.keyCode == 32);
//   };

// build with parcel and run dist on a local server for import to work, otherwise replace with the XMLHttpRequest below
import _songs from '../sets.json'

// var _songs = [];

// var request = new XMLHttpRequest();
// request.open('GET', 'sets.json', true);

// request.onload = function () {
//     if (this.status >= 200 && this.status < 400) {
//         // Success!
//         _songs = JSON.parse(this.response);
//         Init();
//     } else {
//         // We reached our target server, but it returned an error

//     }
// };

// request.onerror = function () {
//     // There was a connection error of some sort
// };

// request.send();


// TODO - function is leftover from calling Init on successful XMLHttpRequest - will refactor
Init();

let _playlistIsOpen = false;

function Init() {

    /*
        Load the handlebars playlist
    */
    const source = document.getElementById('playlist-template').innerHTML;
    const template = Handlebars.compile(source);
    document.getElementById('playlist').innerHTML = template(_songs);


    /*
        When the share svg is pressed, stop all propagation so AmplitudeJS doesn't
        play the song, then copy the link to the clipboard and notify the user 
    */
    const shareLinks = document.getElementsByClassName('playlist-share');

    const shareHandler = function (songObj) {
        const songId = songObj.dataset.id;

        copyTextToClipboard(window.location.origin + window.location.pathname + '?' + songId);

        document.getElementById('copy-' + songId).classList.remove('slide-out-top');
        document.getElementById('copy-' + songId).classList.add('slide-in-top');
        document.getElementById('copy-' + songId).style.display = 'block';

        setTimeout(function () {
            document.getElementById('copy-' + songId).classList.remove('slide-in-top');
            document.getElementById('copy-' + songId).classList.add('slide-out-top');
            document.getElementById('copy-' + songId).style.display = 'none';
        }, 1500);

        const song = _songs[getSongIndex(songId)];
        sendGoogleClickEvent('share', song.artist + ' - ' + song.name);
    };

    for (var i = 0; i < shareLinks.length; i++) {
        shareLinks[i].addEventListener('click', function (e) {
            e.stopPropagation();
            shareHandler(this);
        });
        shareLinks[i].addEventListener('tap', function (e) {
            e.stopPropagation();
            shareHandler(this);
        });
        shareLinks[i].addEventListener('touchstart', function (e) {
            e.stopPropagation();
            // no shareHandler - tap covers us
        });
        shareLinks[i].addEventListener('touchend', function (e) {
            e.stopPropagation();
            // no shareHandler - tap covers us
        });
    }

    /*
        When the download link is pressed, stop all propagation so AmplitudeJS doesn't
        play the song.
    */
    const downloadLinks = document.getElementsByClassName('download-link');

    const downloadHandler = function (songObj) {
        const songId = songObj.getAttribute('data-id')
        const song = _songs[getSongIndex(songId)];
        sendGoogleClickEvent('download', song.artist + ' - ' + song.name);
    };

    for (var i = 0; i < downloadLinks.length; i++) {
        downloadLinks[i].addEventListener('click', function (e) {
            e.stopPropagation();
            downloadHandler(this);
        });
        downloadLinks[i].addEventListener('tap', function (e) {
            e.stopPropagation();
            downloadHandler(this);
        });
        downloadLinks[i].addEventListener('touchstart', function (e) {
            e.stopPropagation();
            // no download handler - tap covers us
        });
        downloadLinks[i].addEventListener('touchend', function (e) {
            e.stopPropagation();
            // no download handler - tap covers us
        });
    }

    const songElements = document.getElementsByClassName('song');

    for (let i = 0; i < songElements.length; i++) {
        /*
            Ensure that on mouseover, CSS styles don't get messed up for active songs.
        */
        songElements[i].addEventListener('mouseover', function () {

            this.style.backgroundColor = '#00A0FF';
            this.querySelectorAll('.song-meta-data .song-title')[0].style.color = '#FFFFFF';
            this.querySelectorAll('.song-meta-data .song-artist')[0].style.color = '#FFFFFF';
            this.querySelectorAll('.song-meta-data .song-genres')[0].style.color = '#FFFFFF';

            if (!this.classList.contains('amplitude-active-song-container')) {
                this.querySelectorAll('.play-button-container')[0].style.display = 'block';
            }

            this.querySelectorAll('svg.now-playing')[0].style.stroke = '#FFFFFF';
            this.querySelectorAll('button.playlist-share svg')[0].style.stroke = '#FFFFFF';
            this.querySelectorAll('svg.playlist-download')[0].style.stroke = '#FFFFFF';
            this.querySelectorAll('.song-duration')[0].style.color = '#FFFFFF';
        });

        /*
            Ensure that on mouseout, CSS styles don't get messed up for active songs.
        */
        songElements[i].addEventListener('mouseout', function () {
            this.style.backgroundColor = '#FFFFFF';
            this.querySelectorAll('.song-meta-data .song-title')[0].style.color = '#272726';
            this.querySelectorAll('.song-meta-data .song-artist')[0].style.color = '#607D8B';
            this.querySelectorAll('.song-meta-data .song-genres')[0].style.color = '#999999';

            this.querySelectorAll('.play-button-container')[0].style.display = 'none';

            this.querySelectorAll('svg.now-playing')[0].style.stroke = '#00A0FF';
            this.querySelectorAll('button.playlist-share svg')[0].style.stroke = '#666666';
            this.querySelectorAll('svg.playlist-download')[0].style.stroke = '#666666';
            this.querySelectorAll('.song-duration')[0].style.color = '#607D8B';
        });

        /*
            Show and hide the play button container on the song when the song is clicked.
        */
        songElements[i].addEventListener('click', function () {
            this.querySelectorAll('.play-button-container')[0].style.display = 'none';
        });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const song = window.location.href.substring(window.location.href.indexOf("?") + 1);
    let startIndex = 0;

    if (song) {
        startIndex = getSongIndex(song)
    }

    /*
        Initializes AmplitudeJS
    */
    Amplitude.init({

        debug: false,

        bindings: {
            37: 'prev',
            39: 'next',
            32: 'play_pause'
        },

        songs: _songs,
        start_song: startIndex,

        preload: 'metadata',

        waveforms: {
            sample_rate: 1
        },

        callbacks: {

            initialized: function () {
                // console.log('AMP Init', Amplitude.getSongs());

                // two bugs with 5.2.0 are addressed here
                // the initial pause() ensures the progress bar works properly on mobile
                // playSongAtIndex() is called because start_song doesn't work, and then we stop() in case the browser allows auto-play
                Amplitude.pause();

                if (startIndex > 0) {
                    Amplitude.playSongAtIndex(startIndex);
                    // Amplitude.skipTo(0, startIndex);
                    Amplitude.stop();
                }

                // another bug workound - when app is installed, it seems to allow auto play (? maybe, not sure), 
                // and the stop() call above works, but doesn't change the pause button to play
                document.getElementById('play-pause').classList.remove('amplitude-playing');
                document.getElementById('play-pause').classList.add('amplitude-paused');

            },

            playlist_changed: function () {
                // console.log('AMP Playlist Changed');
            },

            play: function () {

                const song = _songs[Amplitude.getActiveIndex()];
                sendGoogleClickEvent('play', song.artist + ' - ' + song.name);

                // console.log('AMP Play');
                // document.getElementById('album-art').style.visibility = 'hidden';
                // document.getElementById('large-visualization').style.visibility = 'visible';
            },

            pause: function () {
                // console.log('AMP Pause');
                // document.getElementById('album-art').style.visibility = 'visible';
                // document.getElementById('large-visualization').style.visibility = 'hidden';
            },

            song_change: function () {
                // console.log('AMP Song Change');
            }
        },
        waveforms: {
            sample_rate: 50,
        },
    });

    /*
  Handles a click on the song played progress bar.
*/
    document.getElementById('song-played-progress').addEventListener('click', function (e) {
        const offset = this.getBoundingClientRect();
        const x = e.pageX - offset.left;

        Amplitude.setSongPlayedPercentage((parseFloat(x) / parseFloat(this.offsetWidth)) * 100);
    });

    /*
  Handles a click on the down button to slide down the playlist.
    */
    document.getElementById('playlist-open').addEventListener('click', function () {

        document.getElementById('amplitude-right').classList.remove('slide-out-top');
        document.getElementById('amplitude-right').classList.add('slide-in-top');
        document.getElementById('amplitude-right').style.display = 'block';
        document.getElementById('playlist-close').style.display = 'block';

        // helps prevent back button from closing on phone/pwa
        window.history.pushState({}, '')

        _playlistIsOpen = true;

    });

    /*
        Handles a click on the up arrow to hide the list screen.
    */
    document.getElementById('playlist-close').addEventListener('click', function () {
        document.getElementById('amplitude-right').classList.remove('slide-in-top');
        document.getElementById('amplitude-right').classList.add('slide-out-top');
        document.getElementById('amplitude-right').style.display = 'none';
        document.getElementById('playlist-close').style.display = 'none';

        _playlistIsOpen = false;
    });

    var resizeTimer;
    window.addEventListener('resize', function () {

        // prevent firing the resize code a thousand times
        // clearTimeout(resizeTimer);
        // resizeTimer = setTimeout(function () {
        // }, 250);

        // the playlist is handled differently between mobile vs desktop, so reset a few things if the window's resized
        if (window.innerWidth < 900) {
            document.getElementById('amplitude-right').style.display = 'none';
            document.getElementById('playlist-close').style.display = 'none';
        } else {
            document.getElementById('amplitude-right').classList.remove('slide-in-top');
            document.getElementById('amplitude-right').classList.remove('slide-out-top');
            document.getElementById('amplitude-right').style.display = 'flex';
            document.getElementById('playlist-close').style.display = 'none';
        }
    });

    document.getElementById('loading').style.display = 'none';
    document.getElementById('amplitude-player').style.display = 'flex';
}

window.addEventListener('popstate', function (event) {
    // The popstate event is fired each time when the current history entry changes.

    // make sure the back button closes the playlist if that's what we're looking at (mainly for phones)
    if (_playlistIsOpen) {
        dispatchClick(document.getElementById('playlist-close'));
    } else {

        // TODO consider whether this might cause problems... 
        // I was initially worried that this would break the forward button,
        // but I think that's already broken by pushing the state on load
        history.back(); // otherwise, proceed normally
    }
}, false);

function getSongIndex(songId) {
    const result = _songs.filter(obj => {
        return obj.id === songId.toLowerCase();
    });

    if (result.length > 0) {
        return _songs.indexOf(result[0]);
    }

    return 0;
}

function dispatchClick(element) {
    if ("createEvent" in document) {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", false, true);
        element.dispatchEvent(evt);
    }
    else
        element.fireEvent("onclick");
}

function sendGoogleClickEvent(action, label) {
    console.log('GTAG', { a: action, l: label });

    gtag('event', 'click', {
        'event_category': action,
        'event_label': label
    });

}

function copyTextToClipboard(text) {
    const textArea = document.createElement('textarea');

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakiness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        // var msg = successful ? 'successful' : 'unsuccessful';
        // console.log('Copying text command was ' + msg);
    } catch (err) {
        console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
}